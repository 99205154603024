
import { List, Datagrid, DatagridBody, TextField, NumberField, ShowGuesser} from 'react-admin';
import { ExportButton } from 'react-admin';
import { UrlField, FunctionField } from 'react-admin';
import { Show, SimpleShowLayout } from 'react-admin';
import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import { TextField as Text } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { QueryShow } from './query-details';

const compound_fields = ['amount', 'rb', 'clogp', 'tpsa', 'hacc', 'hdon',
                         'supplier', 'molweight', 'code', 'plate', 'col',
                         'row', 'coordinates', 'sdf', 'image', 'mol',
                         'plate_barcode', 'volume', 'logsw', 'name'];

const DataField = ({ source, record = {} }) => {
    if (['id','formula','plate/row/col'].includes(source)) {
        return <span>{record[source]}</span>;
    } else {
        let [assay, reading_type, time] = source.split(/[\/_]/);
        let [plate, row, col] = record['plate/row/col'].split('/');
        return <a target='_new'
                  href={`${backendurl}/graphs.html?dataset=${assay}&plate=${plate}&reading_type=${reading_type}`}>
                 {record[source]}
               </a>;
    }
};

const MyDatagrid = props => {
  if (props.ids.length > 0) {
    console.log(props.data[props.ids[0]]);  
    return (

      <Datagrid {...props} >
        {Object.keys(props.data[props.ids[0]])
         .filter( fname => !compound_fields.includes(fname) )
         //.map( fname => <TextField source={fname} sortable={false}/> )}
         //.map( fname => <UrlField source={fname} sortable={false}/> )}
         .map( fname => <DataField source={fname} sortable={false}/> )}
         
        
      </Datagrid>
    );
  } else {
    return (<div />);
  }
};

const CompoundShow = props => {
  console.log(props);
  return (
    <Show {...props} title={" Compound #"+props.id}>
      <SimpleShowLayout>
        <img src={props.record.image}/>        
        <TextField source="formula" />
        {
          compound_fields.filter( f => f != 'image').map( f => <TextField source={f}/> )
        }
      </SimpleShowLayout>
    </Show>
  );
};

/*
  `amount` double NOT NULL DEFAULT 0,
  `rb` double NOT NULL DEFAULT 0,
  `clogp` double NOT NULL DEFAULT 0,
  `tpsa` double NOT NULL DEFAULT 0,
  `hacc` double NOT NULL DEFAULT 0,
  `hdon` double DEFAULT NULL,
  `supplier` varchar(10) NOT NULL DEFAULT '',
  `formula` varchar(50) NOT NULL DEFAULT '',
  `molweight` double NOT NULL DEFAULT 0,

*/
export const QueryList = props => {

  return (
    <List {...props} bulkActionButtons={false} actions={<ExportButton maxResults={100000}/>}>
      {/*<MyDatagrid expand={<ShowGuesser/>}>*/}
      {/*<MyDatagrid rowClick="show" expand={<CompoundShow />} >*/}
      {/*<MyDatagrid expand={<CompoundShow />} >*/}
      <MyDatagrid expand={<QueryShow />} >
      </MyDatagrid>
    </List>
  );
};

/*
      <TextField source="id" />
      <TextField source="formula" />
      <TextField source="plate" />
      <TextField source="row" />
      <TextField source="col" />
*/
