import React from 'react';
import { List, Datagrid, TextField, NumberInput, SimpleForm} from 'react-admin';
import PropTypes from 'prop-types';
import { CardActions, ExportButton, CreateButton, RefreshButton } from 'react-admin';
import CsvButton from './csv-button';

const PostActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
  <CardActions>
  </CardActions>
);

export const InputList = props => {
  
  return (
    <List {...props}
          sort={{ field: 'name', order: 'DESC' }}
          exporter={false}
          bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="name" />
        <CsvButton source="csv" sortable={false}/>
      </Datagrid>
    </List>
  );
}
