
import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils, GET_ONE, GET_LIST } from 'react-admin';

//let queryResult = [];
window['queryResult'] = [];

const perform_query = (backendUrl, resource, params, token) => {
  return fetch(`${backendUrl}/${resource}`, {
    method: 'POST', body: JSON.stringify(params),
    headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then( resp => {
    return resp.json();
  }).then( json => {
    return new Promise( (resolve, reject) => {
      console.log('response',json);
      let data = {};
      json.forEach( res => {
        data[res.id] = data[res.id] || {};
        data[res.id]['id'] = res['id'];
        data[res.id]['formula'] = res['formula'];
        data[res.id]['plate/row/col'] = `${res['plate']}/${res['row']}/${res['col']}`;
        // copy fields from result set to new struc
        //['id', 'formula', 'plate', 'row', 'col'].forEach( f => {
        //  data[res.id][f] = res[f];
        //});
        data[res.id][`${res.expdesc}/${res.exptimepoint}`] = res.val;
      });
      console.log(data);
      queryResult = Object.values(data);
      resolve( {data: queryResult, total: queryResult.length} );
    });
  });  
}

const get_response_from_cache = (params) => {
  let start = (params.pagination.page-1) * params.pagination.perPage;
  let end = params.pagination.page * params.pagination.perPage;
  console.log('response from cache ', start, end);
  return new Promise( (resolve, reject) => {
    console.log('response from cache', queryResult);
    resolve( {data: queryResult.slice(start, end), total: queryResult.length} );
  });        
};

export default function ActiScreenDataProvider(backendUrl) {  
  
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    
    if (localStorage.idToken) {
      let token = JSON.parse(localStorage.idToken).rawIdToken;
      options.headers.set('Authorization', `Bearer ${token}`);
    }
    
    return fetchUtils.fetchJson(url, options);
  };

  
  let dataProvider = jsonServerProvider(backendUrl, httpClient);
  
	let dataProviderWrapper = (type, resource, params) => {
    console.log(`fetching ${type} from ${backendUrl}/${resource} using ${JSON.stringify(params)}`);

    // dataProvider is a object that has multiple methods as keys in CamelCase
    // so we convert the TYPE string to camelCase
    let typeKey = type.split('_').map(
      (x,i) => i == 0 ? x.toLowerCase() : x.charAt(0).toUpperCase()+x.substring(1).toLowerCase()
    ).join("");
    
    let token = localStorage.idToken ? JSON.parse(localStorage.idToken).rawIdToken : null;
    if (resource == 'query') {
      
      switch (type) {
        
      case 'FETCH':
        return perform_query(backendUrl, resource, params, token);
        break;
        
      case GET_LIST:
        return get_response_from_cache(params);
        break;

      case GET_ONE:
        return new Promise( (resolve, reject) => {
          dataProvider[typeKey](resource, params)
            .then( resp => {
              //merge with cache
              queryResult.filter( r => r.id == resp.data.id)
                .map( r => {
                  //console.log(JSON.stringify(resp), JSON.stringify(r));
                  let obj = Object.assign({}, r, resp.data);
                  console.log('returning object ', obj);
                  resolve({data: obj});
                });
              resolve(resp);
            });
        });
        break;
      }
      
    } else if (resource == 'zfactor') {

      return fetch(`${backendUrl}/${resource}/${params.id}`, {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then( resp => {
        return resp.json();
      }).then( json => new Promise( (resolve, reject) => {
        console.log(json);
        resolve({data: json});
      }));
      
    } else if (resource == 'histogram') {

      let { id, cutoff, interval } = params;
      
      return fetch(`${backendUrl}/${resource}/${id}?cutoff=${cutoff}&interval=${interval}`, {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`          
        }
      }).then( resp => {
        return resp.json();
      }).then( json => new Promise( (resolve, reject) => {
        console.log(json);
        resolve({data: json});
      }));      
      
    } else {
      
      return dataProvider[typeKey](resource, params);
      
    }    
  };
  
  return dataProviderWrapper;
}
