
import React, { Fragment, useState } from 'react';
import { List, Datagrid, TextField, NumberField, Filter, TextInput, NumberInput, SimpleForm} from 'react-admin';
import { TopToolbar, ExportButton, CreateButton, RefreshButton } from 'react-admin';
import { Button, Checkbox, TextField as Text} from '@material-ui/core';
import { GET_ONE, CREATE, withDataProvider, ShowGuesser } from 'react-admin';
import { showNotification, useRedirect, useDataProvider } from 'react-admin';
import { connect } from 'react-redux';

import { Title } from 'react-admin';
import { Card, CardContent, Typography } from '@material-ui/core';

import AssayDetails from './assay-details';

// a small hack to keep the query parameters
window['query'] = {
  explist: {},
  chemicals: []
};


class Compounds extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: query.chemicals.join('\n')
    };
  }
  
  handleChem(evt) {

    let str = evt.target.value;
    console.log('handling '+str);  
    query.chemicals = str.trim().split('\n').filter( c => c.length > 0 );
    
    this.setState({
      value: evt.target.value
    });
    
  };

  render() {
    console.log('rendering aside ', query.chemicals.join('\n'));
    return (
        <Text
          multiline={true}
          fullWidth={true}
          onChange={this.handleChem.bind(this)}
          value={this.state.value}
          label="Filter by Compounds ids"
          variant="filled"
          margin='dense'
        />
    );
    
    /*
    return (
      <div >
        <Typography variant="title">Compounds</Typography>
        
        <Typography variant="body1">
          Press "enter" after each compound id
        </Typography>
        
        <Text multiline={true} fullWidth={true} onChange={this.handleChem.bind(this)} value={this.state.value}/>

      </div>
    );
    */
  }
};


export class AssayList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    window['list'] = this;
    return (
      <List {...this.props}
            sort={{ field: 'id', order: 'DESC' }}
            bulkActionButtons={false}
            actions={<QueryActions/>}
            filters={<ExpDescFilter />} >
        <Datagrid expand={<AssayDetails />}>
          <SelectField source="Select" sortable={false}/>
          <QueryField source="gt" label=">" sortable={false}/>
          <QueryField source="lt" label="<" sortable={false}/>          
          <QueryField source="Top" sortable={false}/>          
          {/* <TextField source="id" /> */}
          <TextField source="expdesc" label="Desc"/>
          {/* <TextField source="readingtype" label="Type"/>
             <TextField source="normalmethod" label="Normal"/>
             <NumberField source="assayformat" label="Format"/>
             <TextField source="exptimepoint" label="Time"/> */}
        </Datagrid>
      </List>
    );
  }
}

const ExpDescFilter = props => {
  console.log('rendering filter', props);
  return (
    <div >
      <Filter {...props}>
        <TextInput label="Filter by description" source="expdesc" alwaysOn/>
      </Filter>
      <Compounds/>
    </div>
  );
};

const QueryActions = connect(undefined, { showNotification })(props => {

  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  
  function performQuery() {
    console.log('QueryAction', props);
    dataProvider("FETCH", 'query', {data: query})
      .then( (msg) => {
        console.log("result ",msg);
        redirect('/query');        
      })
      .catch( e => {
        console.error(e);
        props.showNotification('error');
      });    
  }
  
  return (
    <TopToolbar>
      <Button color="primary" onClick={performQuery}>QUERY</Button>
    </TopToolbar>
  );
});  


class SelectField extends React.Component {
  
  constructor(props) {
    super(props);
    query.explist[this.props.record.id] = query.explist[this.props.record.id] || {};
    this.state = {
      checked: query.explist[this.props.record.id][this.props.source]
    };    
  }

  handleClick(evt) {
    query.explist[this.props.record.id][this.props.source] = evt.target.checked;
    this.props.record[this.props.source] = evt.target.checked;
    this.setState({
      checked: evt.target.checked
    });
  }
  
  render() {
    //return(<Checkbox onClick={this.handleClick.bind(this)} checked={query[this.props.record.id][this.props.source]}/>);
    return(<Checkbox onClick={this.handleClick.bind(this)} checked={this.state.checked}/>);
  }
  
}

class QueryField extends React.Component {
  constructor(props) {
    super(props);
    query.explist[this.props.record.id] = query.explist[this.props.record.id] || {};
    this.state = {
      val: query.explist[this.props.record.id][this.props.source]
    };
  }

  handleChange(evt) {
    console.log('change', this.props.source+" "+evt.target.value);
    query.explist[this.props.record.id][this.props.source] = parseFloat(evt.target.value);
    this.props.record[this.props.source] = evt.target.value;
    this.setState({
      val: evt.target.value
    });
  }
  
  render() {
    return (<Text type="number" onChange={this.handleChange.bind(this)}
            //value={query[this.props.record.id][this.props.source]}
                  value={this.state.val}
                  style={{width: '4em'}}
            />);
  }
}

