
import React from 'react';
import { Show } from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

let timeStart, timeEnd, calculator;

function calcStart()
{
	//btnGen.setEnabled(false);
	//btnTerminate.setEnabled(true);
	timeStart = Date.now();
}

function calcEnd()
{
	//btnGen.setEnabled(true);
	//btnTerminate.setEnabled(false);
	timeEnd = Date.now();
}

function generate3D(mol, callback)
{
	console.log('Calculating...');
	calcStart();
	calculator = Kekule.Calculator.generate3D(
    mol,
    {'forceField': ''},
		function(generatedMol){
			calcEnd();
			var elapse = (timeEnd - timeStart) / 1000;

			console.log('Calculation done in ' + elapse + ' sec');
      callback(generatedMol);
		},
		function(err)
		{
			if (err)
			{
				calcEnd();
				console.log(err.getMessage? err.getMessage(): err);
				Kekule.error(err);
			}
		}
	);
  window['calculator'] = calculator;
}

function terminate()
{
	console.log('Terminated by user');
	calcEnd();
	if (calculator)
	{
		calculator.halt();
	}		
}


const MODE_2D = 'MODE_2D',
      MODE_3D = 'MODE_3D',
      MODE_WORKING = 'MODE_WORKING';

class CustomLayout extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);  
    this.state = {
      viewMode: MODE_2D,
    };
  }

  componentDidMount() {
    console.log('component mounted '+this.props.record.id);
    this.chemViewer = new Kekule.ChemWidget.Viewer(document.getElementById('chemViewer'+this.props.record.id));
    this.chemViewer.setEnableToolbar(true);
    this.chemViewer
      .setEnableToolbar(true)
      .setEnableDirectInteraction(true)
      .setEnableEdit(true)
      .setToolButtons([
        // 'loadData', 'saveData',
        'molDisplayType', 'molHideHydrogens',
        'zoomIn', 'zoomOut',
        'rotateLeft', 'rotateRight', 'rotateX', 'rotateY', 'rotateZ',
        'reset', 'openEditor', 'config'
      ]);
    
    window['viewer'] = this.chemViewer;
  }
  
  componentDidUpdate() {
    if (!this.chemViewer.getChemObj()) {
      this.switchTo2D();
    }
  }

  switchTo2D() {
    this.chemViewer.setRenderType(Kekule.Render.RendererType.R2D);
    let mol = this.props.record['mol'];
    if (mol) {      
      var myMolecule = Kekule.IO.loadFormatData(mol, 'mol');
      this.chemViewer.setChemObj(myMolecule);
    }          
  }
  
  handle3D() {
    let mol = this.chemViewer.getChemObj();
    this.setState({
      viewMode: MODE_WORKING
    });

    generate3D(mol, (generatedMol) => {
      this.chemViewer.setChemObj(generatedMol);
      this.chemViewer.setRenderType(Kekule.Render.RendererType.R3D);
      this.chemViewer.setMoleculeDisplayType(Kekule.Render.Molecule3DDisplayType.BALL_STICK);
      this.setState({
        viewMode: MODE_3D
      });
    });
    
  }

  handle2D() {
    this.switchTo2D();
    this.setState({
      viewMode: MODE_2D
    });    
  }

  handleCancel() {
    try {
      terminate();
    } catch (err) {
      console.log(err);
      this.setState({
        viewMode: MODE_2D
      });                      
    }
  }
  
  render() {
    let button;

    switch (this.state.viewMode) {
    case MODE_2D:
      button = (<Button onClick={this.handle3D.bind(this)}>Generate 3D</Button>);
      break;
    case MODE_3D:
      button = (<Button onClick={this.handle2D.bind(this)}>Back to 2D</Button>); 
      break;
    case MODE_WORKING:
      button = (<Button onClick={this.handleCancel.bind(this)}>
                    <div>Cancel Generate</div>
                    <CircularProgress size={24}/>
                  </Button>); 
      break;
    }

    return (
      <div>
        <div>{button}</div>
        <div id={'chemViewer'+this.props.record.id} style={{width:"60vw", height:"50vh", borderStyle:'solid'}} />
      </div>
    );
  }  
}

export const QueryShow = props => {
  console.log('rendering QueryShow');
  return (
    <Show {...props}>
      <CustomLayout/>
    </Show>
  );
};


let testMol = `Molecule Name
  CHEMDOOD08070920033D 0   0.00000     0.00000     0
[Insert Comment Here]
 22 23  0  0  0  0  0  0  0  0999 V2000
   -0.7958    1.3125    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0
    0.1500    0.1500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    2.0500   -2.1667    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    0.0042    1.0125    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0
   -0.9333    2.1500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    2.8667   -2.4667    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    1.9042   -1.3042    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
   -1.7333    2.4708    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    1.1042   -1.0042    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0
   -0.5125   -0.3875    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0
   -3.1958    2.2333    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0
    0.9667   -0.1500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    2.5875   -0.7667    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    3.5292   -1.9292    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    1.3875   -2.7042    0.0000 Cl  0  0  0  0  0  0  0  0  0  0  0  0
    3.3875   -1.0667    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    4.3292   -2.2292    0.0000 Cl  0  0  0  0  0  0  0  0  0  0  0  0
   -2.3958    1.9333    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
   -1.8708    3.3125    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
   -3.3333    3.0500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
   -0.2708    2.7125    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
   -2.6708    3.6125    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
  2  4  1  0  0  0  0
  3  7  1  0  0  0  0
  4  1  1  0  0  0  0
  5  1  2  0  0  0  0
  6  3  2  0  0  0  0
  7  9  1  0  0  0  0
  8  5  1  0  0  0  0
  9 12  1  0  0  0  0
 10  2  2  0  0  0  0
 11 18  1  0  0  0  0
 12  2  1  0  0  0  0
 13  7  2  0  0  0  0
 14 16  2  0  0  0  0
 15  3  1  0  0  0  0
 16 13  1  0  0  0  0
 17 14  1  0  0  0  0
 18  8  2  0  0  0  0
 19  8  1  0  0  0  0
 20 22  1  0  0  0  0
 21  5  1  0  0  0  0
 22 19  2  0  0  0  0
 11 20  2  0  0  0  0
 14  6  1  0  0  0  0
M  END
> <DATE>
07-08-2009
`;
