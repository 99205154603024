import React, { Component } from 'react';
import {
  showNotification,
  GET_ONE,
  useDataProvider,
  useRefresh,
  downloadCSV  
} from 'react-admin';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

function downloadFile(path) {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  fakeLink.setAttribute('href', path);
  fakeLink.setAttribute('download', '');  
  fakeLink.click();

}

const processButton = connect(undefined, {showNotification})(props => {

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  
  function handleClick() {
    dataProvider(GET_ONE, 'inputs', { id: props.record.name })
      .then( (msg) => {
        downloadFile(`${backendurl}/output/${msg.data.csv}`);
        refresh();
      })
      .catch( e => {
        console.error(e);
        showNotification('error');
      });

    // refresh after one second to display 'processing...'
    setTimeout(refresh, 500);
  }

  if (props.record.csv) {
    if (props.record.size > 0) {
      return <Button label="Download" onClick={handleClick}>download</Button>;
    } else {
      return <Button label="Processing" disabled={true}>processing...</Button>;
    }
  } else {
    return <Button label="Process" onClick={handleClick}>process</Button>;
  }
  
});

class CsvButton extends Component {
  
  handleClick() {
    const { push, dataProvider, dispatch, record } = this.props;

    dataProvider(GET_ONE, 'inputs', { id: record.name })
      .then( (msg) => {
        downloadFile(`${backendurl}/output/${msg.data.csv}`);        
      })
      .catch( e => {
        console.log(e);
        dispatch(showNotification('error'));
      });
  }

  render() {
    if (this.props.record.csv) {
      return <Button label="Button" onClick={this.handleClick.bind(this)}>download</Button>;      
    } else {
      return <Button label="Process" onClick={this.handleClick.bind(this)}>process</Button>;
    }
  }
}

//export default withDataProvider(CsvButton);
export default processButton;
