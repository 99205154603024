import React from 'react';
import { Create,SimpleForm, TextInput, useRedirect } from 'react-admin';
import { Show, ArrayField, Datagrid, SimpleShowLayout, TextField, downloadCSV } from 'react-admin';
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { Title } from 'react-admin';


export default () => {
  const redirect = useRedirect();
  
  function find_hits() {    
    redirect('/assays');
  }

  function load_data() {    
    redirect('/inputs');
  }

  function graphs() {    
    redirect('/graphs');
  }

  return (
    <Card>
      <Title title="Home" />
      <CardContent>
        
        <h1>Welcome to ActiScreen</h1>

        <div style={{display: 'grid', textAlign: 'left', justifyContent: 'left'}}>
          
          <Button style={{justifyContent: 'left'}} onClick={find_hits}>Find_Hits</Button>
          <div>Find hits and examine details from screens</div>

          <Button style={{justifyContent: 'left'}} onClick={graphs}>Graphs</Button>
          <div>See graphs for a screen</div>

          <Button style={{justifyContent: 'left'}} onClick={load_data}>Load_Data</Button> 
          <div>Load data from robotic system</div>          
          
        </div>
        
      </CardContent>
    </Card>
  );
}


