import { Card, CardContent,CardActions,  Button,  Grid, FormLabel, Typography, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Title, Show, SimpleShowLayout, TabbedShowLayout, TextField, NumberField } from 'react-admin';
import { Button as RawButton, TextField as Text} from '@material-ui/core';
import { withDataProvider } from 'react-admin';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/*
const styles = theme => ({
  root: {
    flexGrow: 1,
  },      
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});
*/

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "50vh",
    backgroundColor: theme.palette.background.paper,
    overflow: 'scroll'
  },
  card: {
    minWidth: 200,
  },
  cardContent: {
    padding: theme.spacing.unit * 0.8    
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class CustomLayoutHelper extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.classes = this.props['classes'];
    this.dataProvider = this.props['dataProvider'];
    this.state = {
      currentTab: "details"
    };
    
  }

  handleTabChange(evt, value) {
    console.log(value);
    this.setState({
      currentTab: value
    });
  }

  detailsTab() {
    const classes = this.classes;
    const fields = Object.keys(this.props.record).filter( k => !(['expdesc','id'].includes(k)));
    
    return (
      <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
        {fields.map(
          k => <Grid item xs={4}>
                 <Card className={classes.card}>
                   <CardContent className={classes.cardContent}>
                     <Typography className={classes.title} color="textSecondary" gutterBottom>{k}</Typography>
                     <Typography component="p">{this.props.record[k]}</Typography>
                   </CardContent>
                 </Card>
               </Grid>
        )}
      </Grid>      
    );
  }

  zfactorTab() {

    if (!this.state.zfactor) {
      this.dataProvider("FETCH", 'zfactor', {id: this.props.record.id})
        .then( (msg) => {
          console.log("result ",msg);
          this.setState({
            zfactor: msg
          });
          
        })
        .catch( e => {
          console.log(e);
        });
    }

    let table = this.state.zfactor ? (      
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Plate</TableCell>
            <TableCell>Z Factor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.zfactor.data.map(row => (
            <TableRow key={row.plate}>
              <TableCell component="th" scope="row">
                {row.plate}
              </TableCell>
              <TableCell align="right">{row.zfactor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (<div/>);
    
    return table;
  }

  fetch_histogram() {
    let cutoff = this.state.cutoff ? this.state.cutoff : 3;
    let interval = this.state.interval ? this.state.interval : 0.1;
    this.dataProvider("FETCH", 'histogram', {id: this.props.record.id, cutoff: cutoff, interval: interval})
      .then( (msg) => {
        console.log("result ",msg);
        this.setState({
          histogram: msg,
          cutoff: cutoff,
          interval: interval
        });
        this.updateChart();
      })
      .catch( e => {
        console.log(e);
      });
  }  
  
  histogramTab() {

    this.elemId = "hist"+this.props.record.id;
    
    if (!this.state.histogram) {
      this.fetch_histogram();
    } 

    return (<div>
              <div style={{display: 'flex'}}>
                <Text type="number" helperText="cutoff"
                      value={this.state.cutoff}
                      variant="standard"
                      onChange={(evt)=>this.setState({histogram: null, cutoff: evt.target.value})}/> &nbsp;
                <Text type="number" helperText="interval"
                      value={this.state.interval}
                      variant="standard"                  
                      onChange={(evt)=>this.setState({histogram: null, interval: evt.target.value})}/> &nbsp;
                <RawButton onClick={this.histogramScaleAction.bind(this,'logarithmic')}>Log Scale</RawButton>
                <RawButton onClick={this.histogramScaleAction.bind(this, 'linear')}>Linear Scale</RawButton>
                <RawButton onClick={this.exportImageAction.bind(this)}>Export_PNG</RawButton>
                
              </div>            
              <canvas id={this.elemId}></canvas>
            </div>);
  }

  histogramScaleAction(scaleType) {
    this.histogram.options.scales.yAxes[0].type = scaleType;
    this.histogram.update();
  }

  LinearScaleAction() {
  }

  exportImageAction() {
    if (this.histogram) {
      let b64 = this.histogram.toBase64Image();
      //console.log(b64);
      var a = document.createElement('a');
      a.href = b64
      a.download = `${this.props.record.expdesc}.png`;
      
      // Trigger the download
      a.click();
    }
  }
  
  componentDidUpdate() {
    //setTimeout(this.updateChart.bind(this), 0);
    //this.updateChart();
  }

  updateChart() {
    console.log('rendering historgram');
    let ctx = document.getElementById(this.elemId);    
    if (this.state.histogram && ctx) {
      ctx.getContext('2d');
      let histData = {
        
        labels: this.state.histogram.data.map( d => d.bin.toFixed(2) ),
        datasets: [ {
          data: this.state.histogram.data.map( d =>  d.count),
          backgroundColor: 'rgba(0, 0, 0, 1)'          
        }]

        /*
        datasets: [ {
          data: this.state.histogram.data.map( d => {
            return {
              'x': parseFloat(d.bin - 1),
              'y': d.count,              
            };
          }),          
          backgroundColor: 'rgba(0, 0, 0, 1)',
          }]
        */
        
      };
      window['histData'] = histData;
      console.log(histData);
      if (this.histogram) {
        this.histogram.data = histData;
        this.histogram.update();
      } else {
        var myBarChart = new Chart(ctx, {
          type: 'bar',
          data: histData,
          options: {
					  responsive: true,
					  legend: {
						  display: false,
              position: 'top',
					  },
					  title: {
						  display: true,
						  text: `${this.props.record.expdesc}`,
              fontColor: 'rgba(0,0,0,1)'
					  },
            
            scales: {
              xAxes:[{
                ticks: {
                  fontColor:'rgba(0,0,0,1)',
                }
              }],
              yAxes:[{
                ticks: {
                  fontColor:'rgba(0,0,0,1)',
                }
              }],
            }
				  }
        });
        window['myBarChart'] = myBarChart;
        this.histogram = myBarChart;
      }
    }
  }
  
  render() {
    const classes = this.classes;
    
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={this.state.currentTab} onChange={this.handleTabChange.bind(this)}>
            <Tab value="details" label="Details" />
            <Tab value="histogram" label="Histogram" />
            { /* removing zfactor tab as per requirement <Tab value="z-factor" label="ZFactor" /> */ }
          </Tabs>
        </AppBar>
        { this.state.currentTab === 'details' && this.detailsTab() }
        { this.state.currentTab === 'histogram' && this.histogramTab() }
        { this.state.currentTab === 'z-factor' && this.zfactorTab()}
      </div>
    );
  }
}

const CustomLayout = withStyles(styles)(withDataProvider(CustomLayoutHelper));

const handleHistogram = () => {
  console.log('histogram');
};

const handleZFactor = () => {
  console.log('zFactor');
};

const AssayDetails = props => {
  return (
    <Show {...props} title={" #"+props.id}>
      <CustomLayout/>
    </Show>    
  );
};

export default AssayDetails;

/*
<Button onClick={handleHistogram}>Histogram</Button>
<Button onClick={handleZFactor}>zFactor</Button>

      <SimpleShowLayout>

        <TextField source="readingtype" label="Type"/>
        <TextField source="normalmethod" label="Normal"/>
        <NumberField source="assayformat" label="Format"/>
        <TextField source="exptimepoint" label="Time"/> 
        <NumberField source="platecount" />
        <NumberField source="minplate" />
        <NumberField source="maxplate" />        
        <NumberField source="minvalue" />        
        <NumberField source="maxvalue" />
        <NumberField source="avgvalue" />        
        <NumberField source="stddevvalue" />

      </SimpleShowLayout>

*/
