import React from 'react';
import { connect } from 'react-redux';

import { userLogin } from 'react-admin';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';

function google_init() {

  if (window["handleCredentialResponse"] != null) {
    google.accounts.id.initialize({
      client_id: "763117789876-svok9p528a0lgutkrgb617rf71bi02rh.apps.googleusercontent.com",
      callback: handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("loginButtonDiv"),
      { theme: "outline", size: "large" }  // customization attributes
    );
    // google.accounts.id.prompt(); // also display the One Tap dialog
  }
};

function LoginPageImpl(props) {

  if (window["handleCredentialResponse"] == null) {
    
    window["handleCredentialResponse"] = (response) => {
      console.log("Encoded JWT ID token: " + response.credential);
      response.idToken = response.credential;
      props.userLogin(response);
    };

    window["onload"] = () => google_init();    
  }
    
  return (
    <div style={{ backgroundImage: "linear-gradient(blue, white)",
                  position: 'fixed', left:0, top:0,
                  width: "100%", height: "100%"  }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: "10%", height: '80%' }}>


        <div style={{ margin: "auto" }}>
          <h1 style={{ textAlign: "center" }}>ACTISCREEN LOGIN</h1>
        </div>
        
        <div style={{ margin: "auto" }}>          
          <div id="loginButtonDiv"/>
        </div>
        
        <div style={{ margin: "auto", textAlign: "center" }}>
          <img src="images/background1.png"/>
          <img src="images/background2.png"/>          
          
        </div>
      </div>
    </div>
  );        
  
}

const LoginPage = connect(undefined, { userLogin })(LoginPageImpl);

export { LoginPage };

export default function ActiScreenAuthProvider(type, params) {

  console.log('auth provider', type, JSON.stringify(params));

  switch (type) {
    
  case AUTH_LOGOUT:
    if (localStorage.idToken) {
      localStorage.removeItem('idToken');
    }
    console.log('logout',params);

    setTimeout( () => {
      google_init();
    }, 0);

    return Promise.resolve();
    
  case AUTH_LOGIN:
    console.log('login');
    if (params.idToken) {
      localStorage.setItem('idToken', JSON.stringify(params.idToken));

      try {
        let email = JSON.parse(atob(localStorage.idToken.split('.')[1])).email;
        let name = JSON.parse(atob(localStorage.idToken.split('.')[1])).name;

        console.log(`${name} ${email} login`);
        
        return Promise.resolve();        
      } catch (e) {
        console.error(e);
        return Promise.reject();
      }

    } else {
      return Promise.reject();
    }

  case AUTH_ERROR:
    return new Promise( (resolve, reject) => {
      resolve();
      /*
      .then( res => {
        //props.userLogin(res);
        console.log('token renew successful');
        localStorage.setItem('idToken', JSON.stringify(res.idToken));
        window.location.reload();
        resolve();
      }).catch( err => {
        console.log('token renew failed, send to login');        
        localStorage.removeItem('idToken');
        reject();
      });
      */
    });
    
  case AUTH_GET_PERMISSIONS:
    return Promise.resolve();
    
  case AUTH_CHECK:
    if (localStorage.getItem('idToken')) {
      let email = JSON.parse(atob(localStorage.idToken.split('.')[1])).email;

      return Promise.resolve();
      
      // uncomment the following to allow access control.
      /*
      if (['jmadar@gmail.com'].includes(email)) {
        return Promise.resolve();
      } else {
        alert('login invalid');
        localStorage.removeItem('idToken');
        return Promise.reject();
      }
      */
      
    } else {
      return Promise.reject();      
    }    
    
  default:
    //return Promise.resolve();
    if (localStorage.getItem('idToken')) {      
      return Promise.resolve();
    } else {
      return Promise.reject();      
    }
    
  }
  
}
