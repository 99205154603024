import React from "react";
import ReactDOM from "react-dom";
import { Show, Admin, Resource, Title, ListGuesser, ShowGuesser } from 'react-admin';
import { Route } from 'react-router-dom';

import { InputList } from './inputs';
import { QueryResult, AssayList } from './assays';
import { QueryList } from './query';
import { QueryShow } from './query-details';
import { GenCode, ShowCode } from './gencode';
import Home from './home';

import ActiScreenDataProvider from './data-provider';
import ActiScreenAuthProvider, {LoginPage} from './auth-provider-google';
//import ActiScreenAuthProvider from './auth-provider-basic';

import blue from '@material-ui/core/colors/blue';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import { Card, CardContent } from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import CreateIcon from '@material-ui/icons/Create';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import TimelineIcon from '@material-ui/icons/Timeline';

import { Layout, AppBar, UserMenu, MenuItemLink } from 'react-admin';

import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: indigo,
    type: 'light',
  },
  props:{
    MuiTableCell: {
      size: 'small'
    },    
  }  
});

const MyUserMenu = props => {
  
  let profile = localStorage.idToken ? JSON.parse(atob(localStorage.idToken.split('.')[1])) : null;
  console.log('profile ' + profile);
  return (
    <UserMenu label={profile ? profile.name : ''} {...props}>
    </UserMenu>
  );
};

const MyAppBar = props => {
  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
    </AppBar>
  );
};

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

class Dashboard extends React.Component{

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card>
        <Title title="ActiScreen" />
        <CardContent>
          
        </CardContent>
      </Card>
    );
  }
}

function GraphShow() {
  location.href="/graphs.html";
  return (
    <div>
      <a href="/graphs.html">Graphs</a>
    </div>
  );
}

class ActiScreen extends React.Component {
    
  constructor(props) {
    super(props);
    this.dataProvider = ActiScreenDataProvider(props.backend);
    this.authProvider = ActiScreenAuthProvider;
  }
  
  render() {
	  return (
	    <Admin        
        theme={theme}
        title="ActiScreen"
        dashboard={Home}
        dataProvider={this.dataProvider}
        authProvider={this.authProvider}
        loginPage={LoginPage}
        Layout={MyLayout}        
      >
        <Resource options={{ label: 'Find Hits' }} name="assays" list={AssayList} icon={CreateIcon}/>        
        <Resource options={{ label: 'Results' }} name="query" list={QueryList} show={QueryShow} icon={QueryBuilderIcon}/>
        <Resource options={{ label: 'Load Data' }} name="inputs" list={InputList} icon={AddToQueueIcon}/>
        <Resource name="graphs" list={GraphShow} icon={TimelineIcon}/>        
        <Resource name="codes" list={GenCode} show={ShowCode} icon={NoteAddIcon}/>


        
      </Admin>
    );
  }
}


var mountNode = document.getElementById("app");

var backendurl = document.location.port == "" ? document.location.origin : `http://${document.location.hostname}:8080`;
window['backendurl'] = backendurl;

console.log(`Using backend ${backendurl}`);
ReactDOM.render(<ActiScreen backend={backendurl}/>, mountNode);
