import React from 'react';
import { Create,SimpleForm, TextInput } from 'react-admin';
import { Show, ArrayField, Datagrid, SimpleShowLayout, TextField, downloadCSV } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

export class GenCode extends React.Component {
  constructor(props) {
    super(props);    
  }
  
  render() {
    return(
      <Create {...this.props}>
        <SimpleForm redirect="show">
          <TextInput source="Prefix" />
          <TextInput source="Start" />
          <TextInput source="End" />          
        </SimpleForm>
      </Create>
    );
  }
}


class PostShowActions extends React.Component{
  constructor(props) {
    super(props);
  }
  
  csvAction() {
    downloadCSV(this.props.data.list.map( v => v["code"] ).join('\n'), this.props.data.id); 
  }

  render() {
    return (
      <CardActions>
        
        <Button color="primary" onClick={this.csvAction.bind(this)}>Download CSV</Button>
      </CardActions>
    );
  }
}

export class ShowCode extends React.Component {
  constructor(props) {
    super(props);    
  }

  

  render() {
    return(
      <Show actions={<PostShowActions />} {...this.props}>
 
          <ArrayField source="list">
            <Datagrid>
              <TextField source='code'/>            
            </Datagrid>
          </ArrayField>



      </Show>
    );
  }
}
